// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import ReactGA from "react-ga4";
import Home from "./components/Home";
import Blog from "./components/Blog";
import PaperOfTheWeek from "./components/PaperOfTheWeek";
import AISimulator from "./components/AISimulator";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./App.css";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

// Create a component to handle route changes
function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <AppContainer>
        <RouteTracker />
        <Header />
        <ContentWrapper>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/potw" element={<PaperOfTheWeek />} />
            <Route path="/aiSim" element={<AISimulator />} />
          </Routes>
        </ContentWrapper>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
