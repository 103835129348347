import React from "react";
import styled from "styled-components";
import AISimulatorStyled from "./blogComponents/AISimulator";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;

  @media (max-width: 900px) {
    padding: 10px;
  }
`;

function AISimulator() {
  return (
    <Container>
      <AISimulatorStyled />
    </Container>
  );
}

export default AISimulator;
