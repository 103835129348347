/* eslint-disable no-shadow */
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import { Link } from "react-router-dom";

// Styled Components
const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`;

const CardHeader = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
`;

const SubTitle = styled.h3`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 0.9rem;
  color: #666;
  font-style: italic;
  margin: 0.5rem 0;
  gap: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;

    /* Mobile: show shortened links */
    .mobile-only {
      display: inline;
    }
    .desktop-only {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .mobile-only {
      display: none;
    }
    .desktop-only {
      display: inline;
    }
  }
`;

const HeaderLink = styled.a`
  color: #2563eb;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 1.25em;
    height: 1.25em;
  }
`;

const CardContent = styled.div`
  padding: 1.5rem;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    gap: 1.5rem;
  }
`;

const ScenarioDisplay = styled.div`
  text-align: center;
  padding: 1rem;
  background: #f9fafb;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem 1rem;
  }
`;

const EmojiDisplay = styled.div`
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
`;

const ScenarioTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const ScenarioDetail = styled.div`
  font-size: 0.875rem;
  color: #666;
`;

const SliderContainer = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const SliderLabel = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const Slider = styled.input.attrs({ type: "range" })`
  width: 100%;
  margin: 0.5rem 0;
`;

const SideControls = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    width: 16rem;
    padding-left: 1rem;
    border-left: 1px solid #eee;
  }
`;

const StatsDisplay = styled.div`
  font-size: 0.875rem;
  color: #666;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ChartContainer = styled.div`
  height: 24rem;
`;

const AdaptationMetric = styled.div`
  color: ${(props) => (props.$isFast ? "#dc2626" : "#16a34a")};
`;

function AISimulatorStyled() {
  // Calculate current date in decimal years
  const getCurrentYear = () => {
    const now = new Date();
    return now.getFullYear() + (now.getMonth() + now.getDate() / 30) / 12;
  };

  // Constants
  const EPSILON = 0.0001;
  const MIN_START_YEAR = 1955;

  // State for user-adjustable parameters
  const [maxValue, setMaxValue] = useState(2.0);
  const [steepness, setSteepness] = useState(0.35);
  const [fixedPointValue, setFixedPointValue] = useState(0.65);
  const [adaptationRate, setAdaptationRate] = useState(0.3);
  const FIXED_POINT_YEAR = getCurrentYear();

  // Calculate the year when we hit 95% of max value
  const calculateBreakthroughYear = useMemo(() => {
    if (maxValue <= 0.8) return null;

    const targetValue = 0.95 * maxValue;
    const x0 = FIXED_POINT_YEAR + Math.log(maxValue / fixedPointValue - 1) / steepness;
    const year = x0 + Math.log(maxValue / targetValue - 1) / -steepness;

    return year;
  }, [maxValue, FIXED_POINT_YEAR, fixedPointValue, steepness]);

  // Calculate adaptation requirements
  const adaptationMetrics = useMemo(() => {
    if (maxValue <= 0.8 || !calculateBreakthroughYear) return null;

    const timeToReach = calculateBreakthroughYear - FIXED_POINT_YEAR;
    // Using log to make it less sensitive to small changes
    const alpha = 0.5;
    const requiredAdaptationTime = Math.exp(alpha * maxValue) / adaptationRate;
    const isFastGrowth = timeToReach < requiredAdaptationTime;

    return {
      timeToReach,
      requiredAdaptationTime,
      isFastGrowth,
    };
  }, [maxValue, calculateBreakthroughYear, FIXED_POINT_YEAR, adaptationRate]);

  // Determine scenario based on max value and growth rate
  const getScenarioEmoji = useMemo(() => {
    if (maxValue <= 0.8)
      return {
        emoji: "🥱",
        description: "Not Impactful",
        detail: "AI remains a limited tool",
      };

    const isFastGrowth = adaptationMetrics?.isFastGrowth;
    const breakthrough = Math.round(calculateBreakthroughYear);

    if (maxValue <= 3.0) {
      return isFastGrowth
        ? {
            emoji: "🤑",
            description: "Disruptive Business Revolution",
            detail: `95% of peak impact by ${breakthrough}`,
          }
        : {
            emoji: "💼",
            description: "Steady Business Evolution",
            detail: `95% of peak impact by ${breakthrough}`,
          };
    }

    return isFastGrowth
      ? {
          emoji: "😈",
          description: "Dangerous Uncontrolled Superintelligence",
          detail: `95% of peak intelligence by ${breakthrough}`,
        }
      : {
          emoji: "😇",
          description: "Beneficial Managed Superintelligence",
          detail: `95% of peak intelligence by ${breakthrough}`,
        };
  }, [maxValue, adaptationMetrics, calculateBreakthroughYear]);

  // Calculate sigmoid curve points and determine appropriate x-axis range
  const { data, startYear, endYear } = useMemo(() => {
    const calculateSigmoid = (year) => {
      const x0 = FIXED_POINT_YEAR + Math.log(maxValue / fixedPointValue - 1) / steepness;
      return maxValue / (1 + Math.exp(-steepness * (year - x0)));
    };

    let currentYear = FIXED_POINT_YEAR;
    while (calculateSigmoid(currentYear) < maxValue * 0.999 && currentYear < FIXED_POINT_YEAR + 1000) {
      currentYear += 0.5;
    }
    const endYear = Math.min(currentYear + 5, FIXED_POINT_YEAR + 1000);

    currentYear = FIXED_POINT_YEAR;
    while (calculateSigmoid(currentYear) > 0.001 && currentYear > MIN_START_YEAR) {
      currentYear -= 0.5;
    }
    const startYear = Math.max(currentYear - 5, MIN_START_YEAR);

    return {
      data: Array.from({ length: Math.ceil((endYear - startYear) * 2) }, (_, i) => ({
        year: startYear + i * 0.5,
        value: calculateSigmoid(startYear + i * 0.5),
      })),
      startYear,
      endYear,
    };
  }, [FIXED_POINT_YEAR, maxValue, fixedPointValue, steepness]);

  // Format year with month for labels
  const formatYear = (year) => {
    const wholePart = Math.floor(year);
    const fraction = year - wholePart;
    const month = Math.round(fraction * 12);
    return month === 0
      ? `${wholePart}`
      : `${wholePart} ${new Date(0, month - 1).toLocaleString("default", { month: "short" })}`;
  };

  return (
    <Card>
      <CardHeader>
        <Title>AI Intelligence Growth Simulator</Title>
        <SubTitle>
          <Link to="/blog">
            <span className="desktop-only">See our latest blog post for detailed explanation and instructions</span>
            <span className="mobile-only">Documentation</span>
          </Link>
          <span>or</span>
          <HeaderLink href="/videos/aiSimPost.mp4" target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <polygon points="10 8 16 12 10 16 10 8" />
            </svg>
            <span className="desktop-only">Watch the demo video</span>
            <span className="mobile-only">Demo Video</span>
          </HeaderLink>
        </SubTitle>
      </CardHeader>

      <CardContent>
        <FlexContainer>
          <MainContent>
            <ScenarioDisplay>
              <EmojiDisplay>{getScenarioEmoji.emoji}</EmojiDisplay>
              <ScenarioTitle>{getScenarioEmoji.description}</ScenarioTitle>
              <ScenarioDetail>{getScenarioEmoji.detail}</ScenarioDetail>
            </ScenarioDisplay>

            <ChartContainer>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" domain={[startYear, endYear]} type="number" tickFormatter={formatYear} />
                  <YAxis domain={[0, maxValue * 1.1]} tickFormatter={(value) => value.toFixed(1)} />
                  <Tooltip formatter={(value) => value.toFixed(2)} labelFormatter={formatYear} />
                  <Line type="monotone" dataKey="value" stroke="#2563eb" dot={false} strokeWidth={2} />
                  <ReferenceLine
                    x={FIXED_POINT_YEAR}
                    stroke="#4b5563"
                    strokeDasharray="3 3"
                    label={{
                      value: "Current",
                      position: "top",
                      fill: "#4b5563",
                      dx: -30,
                      dy: 25, // Adjust position to prevent overlap on mobile
                    }}
                  />
                  {maxValue > 0.8 && (
                    <ReferenceLine
                      x={calculateBreakthroughYear}
                      stroke="#ff6b6b"
                      strokeDasharray="3 3"
                      label={{
                        value: `95% (${formatYear(calculateBreakthroughYear)})`,
                        position: "top",
                        fill: "#ff6b6b",
                        dx: 55,
                        dy: 25, // Adjust position to prevent overlap on mobile
                      }}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
          </MainContent>

          <SideControls>
            <SliderContainer>
              <SliderLabel>Maximum Intelligence Multiplier: {maxValue.toFixed(2)}x</SliderLabel>
              <Slider
                value={maxValue}
                min={0.5}
                max={10}
                step={0.1}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  if (newValue > fixedPointValue + EPSILON) {
                    setMaxValue(newValue);
                  }
                }}
              />
            </SliderContainer>

            <SliderContainer>
              <SliderLabel>Growth Rate: {steepness.toFixed(2)}</SliderLabel>
              <Slider
                value={steepness}
                min={0.01}
                max={1}
                step={0.01}
                onChange={(e) => setSteepness(Number(e.target.value))}
              />
            </SliderContainer>

            <SliderContainer>
              <SliderLabel>Human Adaptability: {adaptationRate.toFixed(1)}</SliderLabel>
              <Slider
                value={adaptationRate}
                min={0.1}
                max={1}
                step={0.01}
                onChange={(e) => setAdaptationRate(Number(e.target.value))}
              />
            </SliderContainer>

            <SliderContainer>
              <SliderLabel>Current AI Level: {fixedPointValue.toFixed(2)}x</SliderLabel>
              <Slider
                value={fixedPointValue}
                min={0.1}
                max={1}
                step={0.01}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  if (newValue < maxValue - EPSILON) {
                    setFixedPointValue(newValue);
                  }
                }}
              />
            </SliderContainer>

            <StatsDisplay>
              <div>
                Display Range: {formatYear(startYear)} to {formatYear(endYear)}
              </div>
              <div>
                Fixed Point: {formatYear(FIXED_POINT_YEAR)} at {fixedPointValue.toFixed(2)}x human intelligence
              </div>
              {adaptationMetrics && (
                <>
                  <div>Years to Reach: {adaptationMetrics.timeToReach.toFixed(1)}</div>
                  <div>Required Adaptation: {adaptationMetrics.requiredAdaptationTime.toFixed(1)} years</div>
                  <AdaptationMetric $isFast={adaptationMetrics.isFastGrowth}>
                    {adaptationMetrics.isFastGrowth ? "Too Fast" : "Manageable"} Growth
                  </AdaptationMetric>
                </>
              )}
            </StatsDisplay>
          </SideControls>
        </FlexContainer>
      </CardContent>
    </Card>
  );
}

export default AISimulatorStyled;
