import React, { useState } from "react";
import styled from "styled-components";

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthorSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }

  h3 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
  }

  p {
    margin: 0;
    color: #777;
  }
`;

const PostContent = styled.div`
  h3 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .post-date {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 10px;
  }

  .toggle-button {
    background-color: #333;
    color: #fff;
    padding: 8px 16px;
    transition:
      background-color 0.3s ease,
      border-color 0.3s ease;
    border: 2px solid #999;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    margin-top: 20px;
    align-self: start;

    &:hover {
      background-color: #555;
      border: 2px solid #333;
    }
  }
`;

function BlogPost({ post, initiallyExpanded }) {
  const { author, authorImage, authorBio, subject, blurb, content, date } = post;
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const addTargetBlank = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
    return doc.body.innerHTML;
  };

  const extractFirstParagraph = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const firstParagraph = doc.querySelector("p");
    return firstParagraph ? firstParagraph.outerHTML : "";
  };

  const renderContent = () => {
    if (!content) {
      return null;
    }

    const processedContent = addTargetBlank(content);
    const firstParagraph = extractFirstParagraph(processedContent);

    // TODO - do the parsing elsewhere and eliminate dangerouslySetInnerHTML?
    return expanded ? (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: processedContent }} />
    ) : (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: firstParagraph }} />
    );
  };

  return (
    <PostContainer>
      <AuthorSection>
        <img src={authorImage} alt={author} />
        <div>
          <h3>{author}</h3>
          <p>{authorBio}</p>
        </div>
      </AuthorSection>
      <PostContent>
        <div className="post-date">{date}</div>
        <h3>{subject}</h3>
        <p>{blurb}</p>
        {renderContent()}
        {!initiallyExpanded && (
          <button type="button" className="toggle-button" onClick={toggleExpanded}>
            {expanded ? "Read Less" : "Read More"}
          </button>
        )}
      </PostContent>
    </PostContainer>
  );
}

export default BlogPost;
