import React from "react";
import styled from "styled-components";

function LinkedInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width="24"
      height="24"
      aria-label="LinkedIn"
    >
      <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z" />
    </svg>
  );
}

const StyledFooter = styled.footer`
  position: relative;
  background-color: #333;
  color: #fff;
  padding: 20px;

  p {
    margin: 0;
    text-align: center;
  }

  .linkedin-icon {
    position: absolute;
    bottom: 18px;
    &:hover {
      background-color: #fff;
    }
    border-radius: 4px;
    height: 24px;
    right: 20px;

    a {
      color: #fff;
      transition: color 0.3s background-color 0.3s;

      &:hover {
        color: #0a66c2; /* LinkedIn blue */
      }
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <p>&copy; {new Date().getFullYear()} Precipex. All rights reserved.</p>
      <div className="linkedin-icon">
        <a
          href="https://www.linkedin.com/company/precipex"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <LinkedInIcon fontSize="large" />
        </a>
      </div>
    </StyledFooter>
  );
}

export default Footer;
