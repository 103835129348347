// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { Buffer } from "buffer";
import ReactGA from "react-ga4";
import App from "./App";

// Setting global Buffer for the entire application
window.Buffer = Buffer;

// Initialize GA4 with your measurement ID
ReactGA.initialize("G-Z785WERB1M"); // Replace with your GA4 measurement ID

// Get the container for your app
const container = document.getElementById("root");

// Create a root
const root = ReactDOM.createRoot(container);

// Initial render: Render the <App /> into the root container
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
