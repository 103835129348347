import React, { useEffect, useState } from "react";
import styled from "styled-components";

const FoundersContainer = styled.div`
  display: flex;
  flex-direction: column; // Changed to column for vertical stacking
  overflow-y: auto; // Enabled vertical scrolling
  padding: 10px 20px;
  gap: 20px;
  align-items: center; // This will center the images vertically
  margin-bottom: 60px;

  &::-webkit-scrollbar {
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  @media (min-width: 1200px) {
    flex-direction: row; // Back to horizontal layout for desktops
    justify-content: center;
    overflow-y: hidden; // Disable vertical scrolling for desktop
    overflow-x: auto; // Enable horizontal scrolling for desktop
  }

  @media (min-width: 601px) and (max-width: 1199px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; // Wrap elements to form a grid
  }

  @media (max-width: 600px) {
    flex-wrap: nowrap;
  }
`;

const FounderImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  flex: 0 0 auto;
  width: 175px; // Fixed width
  height: 240px; // Fixed height

  @media (min-width: 601px) and (max-width: 1199px) {
    max-width: 300px;
    flex-basis: calc(33.33% - 20px);
  }

  @media (max-width: 600px) {
    width: 70%; // Fixed width
    height: 300px;

    // width and height remain unchanged for mobile
  }
`;

const FounderImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border: 3px solid black;
  @media (min-width: 601px) and (max-width: 1199px) {
    background-position-y: center;
  }
`;

const AltTextOverlay = styled.div`
  position: absolute;
  bottom: -3px;
  left: 3px;
  right: -3px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${FounderImageWrapper}:hover & {
    opacity: 1;
  }

  @media (max-width: 600px) {
    opacity: 1;
  }
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const OverlayImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const OverlayImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 35px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 48px;
  padding: 5px 10px;
  cursor: pointer;
`;

function formatAltText({ name, title, status }) {
  return (
    <>
      {name}
      <br />
      {title}, {status}
    </>
  );
}

function FoundersCarousel() {
  const [selectedImage, setSelectedImage] = useState(null);

  const founderData = [
    { name: "Steven Burkett", title: "Founder", status: "Human" },
    {
      name: "Alex Rivera",
      title: "Director of Business Operations",
      status: "AI",
    },
    { name: "Sophia Turing", title: "AI Strategy Director", status: "AI" },
    { name: "Alex Green", title: "Creative Director", status: "AI" },
    { name: "Vicky Mercer", title: "Business Development Lead", status: "AI" },
    { name: "Jordan Lee", title: "Principal Engineer", status: "AI" },
  ];

  const formatFileName = (name) =>
    `${name
      .split(" ")
      .map((word, index) => (index === 0 ? word.toLowerCase() : word))
      .join("")}.png`;

  const basePath = "/people-images/founders/resized/";

  const handleImageClick = (fileName) => {
    setSelectedImage(fileName);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setSelectedImage(null);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <FoundersContainer>
        {founderData.map((founder) => {
          const fileName = formatFileName(founder.name);

          return (
            <FounderImageWrapper key={founder.name} onClick={() => handleImageClick(fileName)}>
              <FounderImage
                src={`${basePath}${fileName}`}
                alt={founder.name}
                style={{ backgroundImage: `url(${basePath}${fileName})` }}
                onClick={() => handleImageClick(fileName)}
              />
              <AltTextOverlay>{formatAltText(founder)}</AltTextOverlay>
            </FounderImageWrapper>
          );
        })}
      </FoundersContainer>
      {selectedImage && (
        <OverlayContainer onClick={handleOverlayClick}>
          <OverlayImageContainer>
            <OverlayImage src={`${basePath}${selectedImage}`} alt={formatAltText(selectedImage)} />
            <CloseButton onClick={handleOverlayClick}>&times;</CloseButton>
          </OverlayImageContainer>
        </OverlayContainer>
      )}
    </div>
  );
}

export default FoundersCarousel;
