import React, { useRef } from "react";
import styled from "styled-components";
import FoundersCarousel from "./Founders";

const Landing = styled.div`
  text-align: center;
  background-color: #fff;
  margin-top: auto;
  margin-bottom: auto;

  h1 {
    font-size: 72px;
    margin-bottom: 40px;

    @media (max-width: 600px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 18px;
    color: #777;
  }
`;

const Tagline = styled.h2`
  font-weight: 400;
  position: relative;
  display: inline-block;
  white-space: nowrap; // Ensure no breaks unless specified

  .strikethrough {
    color: black;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      border-top: 2px solid red;
      transform: rotate(-20deg);
    }
  }

  .handwritten {
    font-family: "Caveat", cursive;
    font-size: 40px;
    color: red;
    position: absolute; // Adjusted via JavaScript
    top: -40px;
    transform: rotate(-10deg);
    white-space: nowrap;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
    margin-top: 0;
    white-space: normal; // Allows natural line breaks on small screens

    .strikethrough,
    .handwritten {
      display: inline-block;
      position: static; // Use static for natural document flow
    }

    .handwritten {
      margin-right: 4px; // Ensure a small gap between words if needed
      margin-left: 8px; // Ensure a small gap between words if needed
      transform: rotate(-5deg);
    }

    .strikethrough::after {
      position: static;
      display: block;
      height: 2px;
      background-color: red;
      transform: rotate(-5deg);
      margin-top: -12px;
      width: calc(130%);
      border-top: 1px solid red;
      margin-left: -5px;
    }
  }
`;

function Home() {
  const webRef = useRef(null);
  const businessRef = useRef(null);

  return (
    <Landing className="home">
      <h1>We are Precipex</h1>
      <Tagline>
        Pushing the boundaries of AI driven&nbsp;
        <span ref={webRef} className="strikethrough">
          web{" "}
        </span>
        <span ref={businessRef} className="handwritten">
          {" "}
          business{" "}
        </span>
        development.
      </Tagline>
      <FoundersCarousel />
    </Landing>
  );
}

export default Home;
