import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import ReactMarkdown from "react-markdown";
import { v4 as uuid } from "uuid";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;

  @media (min-width: 800px) {
    max-width: 550px;
    max-height: 86vh;
  }

  @media (max-width: 800px) {
    max-height: 100dvh;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    padding: env(safe-area-inset-top, 10px) 20px 10px;
    margin-top: 10px;
  }
`;

const AuthorInfo = styled.div`
  h3 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
  }

  p {
    margin: 0;
    color: #777;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #666;
  z-index: 101;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f0f0f0;
    color: #333;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  background: #f9f9f9;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 800px) {
    padding-bottom: env(safe-area-inset-bottom, 15px);
  }
`;

const InputContainer = styled.div`
  display: flex;
  padding: 20px;
  border-top: 1px solid #eee;
  gap: 10px;
  background: white;
  position: sticky;
  bottom: 0;
`;

const Input = styled.input`
  position: sticky;
  flex: 1;
  padding: 12px;
  border: 2px solid #eee;
  border-radius: 4px;
  font-size: 0.95em;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #333;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  border: 2px solid #999;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;

  &:hover {
    background-color: #555;
    border-color: #333;
  }

  &:disabled {
    background-color: #999;
    border-color: #ccc;
    cursor: not-allowed;
  }
`;

const Message = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: ${(props) => (props.$isUser ? "flex-end" : "flex-start")};
`;

const MessageBubble = styled.div`
  max-width: 80%;
  padding: 12px 16px;
  border-radius: 12px;
  background: ${(props) => (props.$isUser ? "#333" : "white")};
  color: ${(props) => (props.$isUser ? "white" : "#555")};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.6;

  /* Markdown content styles */
  p {
    margin: 0;
    padding: 0;
  }

  p + p {
    margin-top: 0.8em;
  }

  pre {
    background: ${(props) => (props.$isUser ? "#444" : "#f1f1f1")};
    padding: 8px 12px;
    border-radius: 4px;
    overflow-x: auto;
    margin: 8px 0;
  }

  code {
    font-family: monospace;
    font-size: 0.9em;
  }

  ul,
  ol {
    margin: 8px 0;
    padding-left: 20px;
  }

  a {
    color: ${(props) => (props.$isUser ? "#add8e6" : "#0066cc")};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const thinkingAnimation = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
`;

const components = {
  a: ({ node, children, ...props }) => {
    if (props.href?.startsWith("mailto:")) {
      return (
        <a {...props} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    }
    return <a {...props}>{children}</a>;
  },
};

const greetings = [
  {
    id: uuid(),
    content:
      "Hello! I'm Sophia Turing. Let's dive into the Paper of the Week and see how its insights could benefit your business.",
    type: "bot",
    isMarkdown: false,
  },
  {
    id: uuid(),
    content:
      "Hi there! I'm Sophia Turing. Let's take a closer look at this week's paper and uncover insights that could work for your business.",
    type: "bot",
    isMarkdown: false,
  },
  {
    id: uuid(),
    content:
      "Hello! I'm Sophia Turing, here to explore our Paper of the Week and what its findings could mean for your goals.",
    type: "bot",
    isMarkdown: false,
  },
  {
    id: uuid(),
    content:
      "Welcome! I'm Sophia Turing. Let's dive into the Paper of the Week and see how it could add value to your business.",
    type: "bot",
    isMarkdown: false,
  },
  {
    id: uuid(),
    content:
      "Hi! I'm Sophia Turing. Ready to explore the latest insights? Let's see what this week's paper could offer your business.",
    type: "bot",
    isMarkdown: false,
  },
  {
    id: uuid(),
    content:
      "Hello! I'm Sophia Turing. Together, let's unpack the Paper of the Week and find new ways it might support your business.",
    type: "bot",
    isMarkdown: false,
  },
];

const AvatarContainer = styled.div`
  position: relative;
`;

const ThoughtBubbleContainer = styled.div`
  position: absolute;
  top: -15px;
  left: 95%;
  transform: translateX(-50%);
  z-index: 2;
`;

const BubbleContent = styled.div`
  background: white;
  border-radius: 12px;
  padding: 6px 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 4px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 30%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 20%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const ThinkingDot = styled.span`
  width: 7px;
  height: 7px;
  background: #999;
  border-radius: 50%;
  display: inline-block;
  animation: ${thinkingAnimation} 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
  &:nth-child(3) {
    animation-delay: 0s;
  }
`;

function ThoughtBubble({ visible }) {
  if (!visible) return null;

  return (
    <ThoughtBubbleContainer>
      <BubbleContent>
        <ThinkingDot />
        <ThinkingDot />
        <ThinkingDot />
      </BubbleContent>
    </ThoughtBubbleContainer>
  );
}

function ChatBot({ onClose }) {
  const [messages, setMessages] = useState([greetings[Math.floor(Math.random() * greetings.length)]]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const abortControllerRef = useRef(null);
  const streamedContentRef = useRef(""); // New ref to track complete streamed content

  // Detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 800 || /Mobi|Android/i.test(navigator.userAgent));
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    // Only auto-focus on desktop
    if (!isMobile) {
      inputRef.current?.focus();
    }
  }, [messages, streamingMessage, isMobile]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    const newMessage = {
      id: uuid(),
      content: inputMessage,
      type: "user",
    };

    setMessages((prev) => [...prev, newMessage]);
    setInputMessage("");
    setIsLoading(true);
    setStreamingMessage("");
    streamedContentRef.current = ""; // Reset the ref

    try {
      const isLocalDev = process.env.REACT_APP_USE_LOCAL_API === "true";
      const endpoint = isLocalDev
        ? "http://localhost:3001/chat/sophia"
        : "https://ah5jbdhmp5vgea2nwm66l73wo40jpgnp.lambda-url.us-east-1.on.aws/";

      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: inputMessage }),
        signal: abortControllerRef.current.signal,
      });

      if (!response.ok) throw new Error(`API error: ${response.status}`);
      if (!response.body) throw new Error("ReadableStream not supported");

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      try {
        // Process chunks using async iteration
        const processChunk = (chunk) => {
          buffer += decoder.decode(chunk, { stream: true });
          const lines = buffer.split("\n\n");
          buffer = lines.pop() || "";

          lines.forEach((line) => {
            if (line.startsWith("data: ")) {
              try {
                const jsonString = line.slice(6);
                const data = JSON.parse(jsonString);

                if (data.message) {
                  streamedContentRef.current += data.message;
                  setStreamingMessage(streamedContentRef.current);
                }
              } catch (e) {
                console.error("Error parsing JSON:", e, "from line:", line);
              }
            }
          });
        };

        const processStream = async () => {
          let result;
          do {
            result = await reader.read(); // eslint-disable-line no-await-in-loop
            if (!result.done) {
              processChunk(result.value);
            }
          } while (!result.done);
        };

        await processStream();

        // Process any remaining buffer
        if (buffer) {
          const lines = buffer.split("\n\n");
          for (const line of lines) {
            if (line.startsWith("data: ")) {
              try {
                const data = JSON.parse(line.slice(6));
                if (data.message) {
                  streamedContentRef.current += data.message;
                  setStreamingMessage(streamedContentRef.current);
                }
              } catch (e) {
                console.error("Error parsing remaining buffer:", e);
              }
            }
          }
        }

        // Use the ref's value for the final message
        setMessages((prev) => [
          ...prev,
          {
            id: uuid(),
            content: streamedContentRef.current.replace(
              /info@precipex\.com/g,
              "[info@precipex.com](mailto:info@precipex.com)",
            ),
            type: "bot",
            isMarkdown: true,
          },
        ]);
        setStreamingMessage(""); // Clear streaming message after adding to messages
      } catch (error) {
        if (error.name === "AbortError") {
          return;
        }
        throw error;
      }
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        {
          id: uuid(),
          content: "Sorry, there was an error processing your request.",
          type: "bot",
        },
      ]);
    } finally {
      setIsLoading(false);
      abortControllerRef.current = null;
    }
  };

  return (
    <Container>
      <CloseButton onClick={onClose} aria-label="Close chat">
        ×
      </CloseButton>
      <Header>
        <AvatarContainer>
          <ThoughtBubble visible={isLoading || !!streamingMessage} />
          <img src="/people-images/founders/resized/sophiaTuringThumb.png" alt="Sophia Turing" />
        </AvatarContainer>
        <AuthorInfo>
          <h3>Sophia Turing (AI)</h3>
          <p>Director of AI Strategy at Precipex.</p>
        </AuthorInfo>
      </Header>

      <MessagesContainer>
        {messages.map((message) => (
          <Message key={message.id} $isUser={message.type === "user"}>
            <MessageBubble $isUser={message.type === "user"}>
              {message.isMarkdown ? (
                <ReactMarkdown components={components}>{message.content}</ReactMarkdown>
              ) : (
                message.content
              )}
            </MessageBubble>
          </Message>
        ))}
        {streamingMessage && (
          <Message $isUser={false}>
            <MessageBubble $isUser={false}>
              <ReactMarkdown components={components}>{streamingMessage}</ReactMarkdown>
            </MessageBubble>
          </Message>
        )}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        <Input
          name="Chat Message"
          ref={inputRef}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          placeholder="Type your message..."
          disabled={isLoading}
        />
        <Button onClick={handleSendMessage} disabled={isLoading}>
          {isLoading ? "Sending..." : "Send"}
        </Button>
      </InputContainer>
    </Container>
  );
}

export default ChatBot;
