import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";

const HeaderContainer = styled.header`
  background-color: #333;
  color: #fff;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  margin-left: 20px;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    background-color: #555;
    border: 1px solid #777;
  }
  &.active {
    background-color: #555;
    border: 1px solid #777;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    padding: 12px 16px;
  }
`;

const ActionLink = styled(NavLink)`
  border: 2px solid #fff;
  border-radius: 4px;
  @media (hover: hover) {
    &:hover {
      background-color: #555;
      border: 2px solid #fff;
    }
  }
`;

const DesktopActionLink = styled(ActionLink)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoAndNav = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 40px;
  width: auto;
`;

// Custom hamburger menu button with CSS-only icon
const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 15px;
  position: relative;

  @media (max-width: 768px) {
    display: block;
    width: 30px;
    height: 30px;
  }

  &::before,
  &::after,
  span {
    content: "";
    display: block;
    background: white;
    height: 3px;
    width: 100%;
    transition: all 0.3s ease;
    position: absolute;
    left: 0;
  }

  &::before {
    top: ${({ "data-is-open": isOpen }) => (isOpen ? "14px" : "6px")};
    transform: ${({ "data-is-open": isOpen }) => (isOpen ? "rotate(45deg)" : "none")};
  }

  span {
    top: 14px;
    opacity: ${({ "data-is-open": isOpen }) => (isOpen ? "0" : "1")};
  }

  &::after {
    top: ${({ "data-is-open": isOpen }) => (isOpen ? "14px" : "22px")};
    transform: ${({ "data-is-open": isOpen }) => (isOpen ? "rotate(-45deg)" : "none")};
  }
`;

const MobileNav = styled.nav`
  display: none;

  @media (max-width: 768px) {
    display: ${({ "data-is-open": isOpen }) => (isOpen ? "flex" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #333;
    flex-direction: column;
    align-items: center;
    padding: 10px 40px;
    z-index: 1000;
    border-top: 1px solid #555;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const DesktopNav = styled.nav`
  display: flex;
  align-items: center;
  background-color: inherit;

  @media (max-width: 768px) {
    display: none;
  }
`;

function Header() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navLinks = [
    { to: "/blog", text: "Blog" },
    { to: "/potw", text: "Sophia's PotW" },
    { to: "/aisim", text: "AI Simulator" },
  ];

  return (
    <HeaderContainer>
      <LogoAndNav>
        <Logo to="/" title="Go to homepage">
          <LogoImage src="/logo_white.png" alt="Company Logo" />
        </Logo>
        <DesktopNav>
          {navLinks.map(({ to, text }) => (
            <NavLink key={to} to={to} className={location.pathname === to ? "active" : ""}>
              {text}
            </NavLink>
          ))}
        </DesktopNav>
        <MenuButton onClick={toggleMenu} data-is-open={isMenuOpen} aria-label="Toggle menu" aria-expanded={isMenuOpen}>
          <span />
        </MenuButton>
      </LogoAndNav>

      <DesktopActionLink target="_blank" to="mailto:info@precipex.com">
        Contact
      </DesktopActionLink>

      <MobileNav data-is-open={isMenuOpen}>
        {navLinks.map(({ to, text }) => (
          <NavLink
            key={to}
            to={to}
            className={location.pathname === to ? "active" : ""}
            onClick={() => setIsMenuOpen(false)}
          >
            {text}
          </NavLink>
        ))}
        <ActionLink target="_blank" to="mailto:info@precipex.com" onClick={() => setIsMenuOpen(false)}>
          Contact
        </ActionLink>
      </MobileNav>
    </HeaderContainer>
  );
}

export default Header;
