import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { marked } from "marked";
import matter from "gray-matter";
import BlogPost from "./BlogPost";

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;

  @media (max-width: 900px) {
    padding: 10px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 15px 0;

  @media (max-width: 900px) {
    width: 100%;
    padding: 20px;
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin: 30px 0;
`;

function Blog() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch("/blog-posts/posts.json");
        const files = await response.json();
        const posts = await Promise.all(
          files.map(async (file) => {
            const postFile = await fetch(`/blog-posts/${file}`);
            const content = await postFile.text();
            const { data, content: markdownContent } = matter(content);
            const htmlContent = marked(markdownContent);
            return {
              ...data,
              content: htmlContent,
            };
          }),
        );
        setBlogPosts(posts);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <BlogContainer>
      {blogPosts.map((post, index) => (
        <React.Fragment key={post.id}>
          <Content>
            <BlogPost post={post} initiallyExpanded={index === 0} />
          </Content>
          {index < blogPosts.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </BlogContainer>
  );
}

export default Blog;
